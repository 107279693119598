<app-rut-socialreason-status-filter (filterOut)="handleFilter($event)"></app-rut-socialreason-status-filter>
<mat-paginator  [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator>
<div class="module-table-wrapper fade-in" *ngIf="dataSource">
    <table class="general-table" style="width: 90px;" [dataSource]="dataSource" #MatTable mat-table matSort (matSortChange)="sortActionHandler($event)">
  
      <!-- Rut Column -->
      <ng-container matColumnDef="guid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>GUID DE DOCUMENTO</th>
        <td mat-cell *matCellDef="let element"> {{element.guiddocument}} </td>
      </ng-container>
  
      <!-- Razón social Column -->
      <ng-container matColumnDef="documentDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>FECHA DEL DOCUMENTO
        <th>
        <td mat-cell *matCellDef="let element"> {{element.date}} </td>
      </ng-container>
  
      <!-- Nombre comercial Column -->
      <ng-container matColumnDef="warehouse">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>SUCURSAL</th>
        <td mat-cell *matCellDef="let element"> {{element.warehouse}} </td>
      </ng-container>
  
      <!-- Región Column -->
      <ng-container matColumnDef="rut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>RUT ASOCIADO</th>
        <td mat-cell *matCellDef="let element"> {{element.rut}} </td>
      </ng-container>
  
      <!-- Comuna Column -->
      <ng-container matColumnDef="socialReason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>RAZON SOCIAL</th>
        <td mat-cell *matCellDef="let element"> {{element.socialReason}} </td>
      </ng-container>
  
      <!-- Estado Column -->
      <ng-container matColumnDef="numDoc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nº DOCUMENTO</th>
        <td mat-cell *matCellDef="let element"> {{element.correlative}} </td>
      </ng-container>
  
      <!-- Estado Column -->
      <ng-container matColumnDef="numDocUntil">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nº DOCUMENTOS HASTA</th>
        <td mat-cell *matCellDef="let element"> {{element.correlative1}} </td>
      </ng-container>
  
      <!-- Estado Column -->
      <ng-container matColumnDef="quantDocGUID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CTDAD. DOCUMENTOS GUID</th>
        <td mat-cell *matCellDef="let element"> {{element.quantityDocs}} </td>
      </ng-container>
  
      <ng-container matColumnDef="documentType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TIPO DE DOCUMENTO</th>
        <td mat-cell *matCellDef="let element"> {{element.documentType}} </td>
      </ng-container>
  
      <ng-container matColumnDef="typeDocumentReference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TIPO DE DOCUMENTO REFERENCIA</th>
        <td mat-cell *matCellDef="let element"> {{element.typeDocRef}} </td>
      </ng-container>
  
      <ng-container matColumnDef="numDocReference1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nº DE DOCUMENTO REFERENCIA 1</th>
        <td mat-cell *matCellDef="let element"> {{element.ref1}} </td>
      </ng-container>
  
      <ng-container matColumnDef="numDocReference2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nº DE DOCUMENTO REFERENCIA 2</th>
        <td mat-cell *matCellDef="let element"> {{element.ref2}} </td>
      </ng-container>
  
      <ng-container matColumnDef="paymentMethod">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>MEDIO DE PAGO</th>
        <td mat-cell *matCellDef="let element"> {{element.paymentMethod}} </td>
      </ng-container>
  
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>MONTO BRUTO</th>
        <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
      </ng-container>
  
      <ng-container matColumnDef="numDocSAP">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nº DOCUMENTO SAP</th>
        <td mat-cell *matCellDef="let element"> {{element.sapNumber}} </td>
      </ng-container>
  
      <ng-container matColumnDef="responseSAP">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>RESPUESTA SAP</th>
        <td mat-cell *matCellDef="let element"> {{element.response}} </td>
      </ng-container>
  
      <ng-container matColumnDef="cuo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CUO</th>
        <td mat-cell *matCellDef="let element"> {{element.cuo}} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
         ></tr><!--(click)="clickRowHandler(row)"-->
  
    </table>
  </div>