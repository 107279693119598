import { HomeComponent } from './home/home.component';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Tab } from './components/tabs-manager/models/tab.model';
import { TabsManagerService } from './components/tabs-manager/tabs-manager.service';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  tabs = new Array<Tab>();
  selectedTab:any;
  title = '|| Sodimac Visor SAP ||';
  textTogglerTigger = false;
  isGreater:any;
  sidenavStatus = true;
  userData = JSON.parse(localStorage.getItem('userData')||'{}');

  constructor(private tabService: TabsManagerService) { }

  ngOnInit(){
    environment.country = localStorage.getItem('country')||'{}';
    this.initializeTabs();
  }
  private initializeTabs() {
    this.tabService.tabSub.subscribe(tabs => {
      if (this.tabs.length < tabs.length) {
        this.isGreater = false;
      }
      this.tabs = tabs;
      this.selectedTab = tabs.findIndex(tab => tab.active);
    });
    this.tabService.addTab(new Tab(HomeComponent, 'Inicio', false), 'bar_chart');
  }
  openSidenav(sidenav:any) {
    sidenav.open();
    this.sidenavStatus = sidenav._opened;
    console.log(this.sidenavStatus);
  }
  closeSidenav(sidenav:any) {
    sidenav.close();
    this.sidenavStatus = sidenav._opened;
    console.log(this.sidenavStatus);
  }

  toggleSidenav(sidenav:any) {
    sidenav.toggle();
    this.sidenavStatus = !this.sidenavStatus;
  }

  removeTab(index: number){
    this.tabService.removeTab(index);
  }

  changeTab(tabId:any) {
    if (tabId !== this.tabs[this.selectedTab].id) {
      console.log('TEST');
      this.isGreater = this.tabService.changeTab(tabId);
    }
  }
}
