import { MsalService } from '@azure/msal-angular';
import { ServicesBffService } from './../../services/services-bff.service';
import { Component, OnInit, Input } from '@angular/core';
import { TabsManagerService } from '../tabs-manager/tabs-manager.service';
import { Tab } from '../tabs-manager/models/tab.model';
import { MenuConstants } from '../menu-constants';
import { UserData } from 'src/app/login/models/user-data.model';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {
  @Input() sidenav: any;
  constructor(
    private tabService: TabsManagerService,
    private authService: MsalService,
    private bffService: ServicesBffService
  ) { }
  textToggler = false;
  menuItems = [
    {
      name: 'Consultas',
      icon: 'apps',
      pages: [
        {
          name: 'Transacciones OK en SAP',
          abbreviation: 'TS',
          link: MenuConstants.TAB_MENU.transactions_ok
        },
        {
          name: 'Rechazo sin respuesta SAP',
          abbreviation: 'RS',
          link: MenuConstants.TAB_MENU.transactions_rejected
        },
        {
          name: 'Incosistencia Origen',
          abbreviation: 'IO',
          link: MenuConstants.TAB_MENU.origin_inconsistency
        },
      ]
    }
  ];
  userData: any
  fullName = '';
  contador: any;
  ngOnInit() {

    environment.country = localStorage.getItem('country') || '{}';

    this.userData = (JSON.parse(localStorage.getItem('userData') || '{}') as UserData);
    localStorage.setItem('intervalo', '10');
    const insterval = 3000 * 1000;
    setInterval(() => {
      // Refresh Services cada 1 hora
      // tslint:disable-next-line: deprecation
      this.bffService.refreshToken().subscribe(async resp => {
        localStorage.removeItem('token');
        localStorage.setItem('token', resp.token);
      });
      localStorage.setItem('intervalo', this.contador + 10 + '');

    },
      insterval
    );
  }
  toggleText() {
    this.sidenav.close();
    setTimeout(() => {
      this.textToggler = !this.textToggler;
      this.sidenav.open();
    }, 400);
  }
  addNewTab(tab: Tab, icon: string) {
    if (this.tabService.tabSub.getValue().length === 5) {
      console.log('no se pueden mas tabs')
      return;
    }
    this.tabService.addTab(
      new Tab(tab.component, tab.title, true), icon
    );
  }
  signOut() {
    localStorage.setItem('country', '');
    localStorage.removeItem('userData');
    this.authService.logout();
    // window.location.reload();
  }
}
