<div class="group-action-buttons">
    <button mat-mini-fab matTooltip="Reenviar" matTooltipShowDelay="450" matTooltipPosition="above"
      [disabled]="!editDisabled" (click)="editClickHandler()" (focus)="preventFocus($event)">
      <mat-icon [inline]="true">refresh</mat-icon>
    </button>
    <button mat-mini-fab matTooltip="Eliminar" matTooltipShowDelay="450" matTooltipPosition="above"
      [disabled]="!showDetailDisabled" (click)="showDetailClickHandler()" (focus)="preventFocus($event)">
      <mat-icon [inline]="true">cancel</mat-icon>
    </button>
  </div>
