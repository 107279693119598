import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { FilterData } from '../filtersrejected/models/filterdata.model';
import { Warehouse } from 'src/app/transactionok/models/warehouse.model';
import { SubmitFiltersService } from 'src/app/services/submit-filters.service';
@Component({
  selector: 'app-rut-socialreason-status-filter',
  templateUrl: './rut-socialreason-status-filter.component.html',
  styleUrls: ['./rut-socialreason-status-filter.component.scss']
})
export class RutSocialreasonStatusFilterComponent implements OnInit {
  @Output() filterOut = new EventEmitter();

  filterData: FormGroup = new FormGroup({});
  filterWarehousesOptions = JSON.parse(localStorage.getItem('warehouses')||'{}') as Warehouse[];
  filterDocumentOptions = JSON.parse(localStorage.getItem('documents')||'{}') as any[];

  constructor(private submitFiltersService: SubmitFiltersService) {}

  ngOnInit(): void {
    this.filterData = new FormGroup({
      warehouse: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      transactioncode: new FormControl(''),
      documentcode: new FormControl(''),
      correlative: new FormControl(''),
    });
  }

  onSubmit() {
    const result = this.submitFiltersService.submit(Object.assign({}, this.filterData.value));
    this.handleFormSubmit(result);
  }

  handleFormSubmit($event: FilterData) {
    this.filterOut.emit($event);
  }

}
