import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { TransactionsokConstants } from '../transactionok/transanctionsok-constants';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { TransactionsOKByFilterInitParam } from '../transactionok/models/transactionsok-by-filter-init-param.model';
import { Observable, of } from 'rxjs';
import { TransactionsOKByFilter } from '../transactionok/models/transactionsok-by-filter.model';
import { Transactiondcto } from '../transactionok/models/transacciondcto.model';
import { Warehouse } from '../transactionok/models/warehouse.model';
@Injectable({
  providedIn: 'root'
})
export class TransactionokService {
  headers = new HttpHeaders();
  constructor(private http: HttpClient) {
    this.headers = this.headers.append('x-country', localStorage.getItem('country') || '{}');
  }
  getTransactionsOKByFilter(transactionsokParams: TransactionsOKByFilterInitParam): Observable<TransactionsOKByFilter> {
    let httpGetParams: HttpParams = new HttpParams();
    httpGetParams = httpGetParams.append('action', 'OK');
    httpGetParams = httpGetParams.append('date', transactionsokParams.date);
    httpGetParams = httpGetParams.append('warehouse', transactionsokParams.warehouse);
    httpGetParams = httpGetParams.append('transactioncode', transactionsokParams.transactioncode);
    httpGetParams = httpGetParams.append('documentcode', transactionsokParams.documentcode);
    httpGetParams = httpGetParams.append('correlative', transactionsokParams.correlative);

    return this.http.get<TransactionsOKByFilter>(TransactionsokConstants.URL_PATH_TRANSACTIONS_OK_BY_FILTER,
      { params: httpGetParams, headers: this.headers }).pipe(
        retry(3),
        catchError(this.handleError<TransactionsOKByFilter>('getTransactionsOK')));
  }
  getTransaccionescdod(): Observable<Transactiondcto> {
    return this.http.get<Transactiondcto>(TransactionsokConstants.URL_PATH_TRANSACCIONESCDOD_BY_FILTER,
      { headers: this.headers }).pipe(
        retry(3),
        catchError(this.handleError<Transactiondcto>('gettransaccionescdod')));
  }

  getDocuments(): Observable<Document> {
    return this.http.get<Document>(TransactionsokConstants.URL_PATH_DOCUMENTS_BY_FILTER,
      { headers: this.headers }).pipe(
        retry(3),
        catchError(this.handleError<Document>('getDocuments')));
  }

  getWarehouses(): Observable<Warehouse> {
    return this.http.get<Warehouse>(TransactionsokConstants.URL_PATH_WAREHOUSE_BY_FILTER,
      { headers: this.headers }).pipe(
        retry(3),
        catchError(this.handleError<Warehouse>('getWarehouses')));
  }
  private handleError<T>(operation = 'operation', result?: T) {
    console.log(operation);

    return (error: HttpErrorResponse): Observable<T> => {
      console.log(error);

      return of(result as T);
    };
  }
}
