<form (ngSubmit)="onSubmit()" [formGroup]="filterData">
    <mat-card class="filter-card mat-elevation-z0">
        <div class="filter-form">
            <mat-form-field>
                <mat-label>Tienda</mat-label>
                <mat-select formControlName="warehouse">
                    <mat-option *ngFor="let warehouse of filterWarehousesOptions" value="{{warehouse.id+'t'}}">{{warehouse.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Fecha de venta</mat-label>
                <input matInput [matDatepicker]="date" formControlName="date">
                <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Tipo documento</mat-label>
                <mat-select formControlName="transactioncode">
                    <mat-option value="">TODOS</mat-option>
                    <mat-option *ngFor="let document of filterDocumentOptions" value="{{document.transactionId}},{{document.cdoc}}">{{document.description}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Numero de documento</mat-label>
                <input matInput formControlName="correlative">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Estado de transacción</mat-label>
                <mat-select formControlName="status">
                    <mat-option value="SIN RESPUESTA">SIN RESPUESTA</mat-option>
                    <mat-option value="RECHAZO">RECHAZO</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-flat-button class="btn-filter" [disabled]="filterData.invalid" type="submit">Buscar</button>
        </div>
    </mat-card>
</form>
