<mat-toolbar>
    <div class="tabs-wrapper">
      <ng-content #tabs></ng-content>
    </div>
    <div class="header-right-side">
      <div class="search-cnt">
        <mat-form-field appearance="legacy" floatLabel="never">
          <mat-icon>search</mat-icon>
          <input matInput>
        </mat-form-field>
      </div>
    </div>
  </mat-toolbar>