import { TransactionsrejectedService } from './../services/transactionsrejected.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionsrejectedComponent } from './transactionsrejected.component';
import { FiltersModule } from '../components/filters/filters.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MaterialModule } from '../core/material.module';



@NgModule({
  declarations: [
    TransactionsrejectedComponent,
  ],
  imports: [
    CommonModule,
    FiltersModule,
    NgxUiLoaderModule,
    MaterialModule
  ],
  providers:[
    TransactionsrejectedService
  ],
  entryComponents:[
    TransactionsrejectedComponent
  ]
})
export class TransactionsrejectedModule { }
