import { TransactionokService } from 'src/app/services/transactionok.service';
import { TransactionokRoutingModule } from './transactionok-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionokComponent } from './transactionok.component';
import { FiltersModule } from '../components/filters/filters.module';
import { MaterialModule } from '../core/material.module';
@NgModule({
  declarations: [
    TransactionokComponent,
  ],
  imports: [
    CommonModule,
    FiltersModule,
    TransactionokRoutingModule,
    MaterialModule
  ],
  providers:[
    TransactionokService
  ],
  entryComponents: [TransactionokComponent]
})
export class TransactionokModule { }
