import { Tab } from './tabs-manager/models/tab.model';
import { TransactionokComponent } from '../transactionok/transactionok.component';
import { TransactionsrejectedComponent } from '../transactionsrejected/transactionsrejected.component';
import { ValidDocumentsComponent } from '../valid-documents/valid-documents.component';

export abstract class MenuConstants {
  static readonly TAB_MENU = {
    transactions_ok: new Tab(TransactionokComponent, 'Transacciones OK en SAP'),
    transactions_rejected: new Tab(TransactionsrejectedComponent, 'Rechazo sin respuesta SAP'),
    origin_inconsistency: new Tab(ValidDocumentsComponent, 'Incosistencia Origen'),
  };
}