import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { FilterData } from '../filtersrejected/models/filterdata.model';
import { Warehouse } from 'src/app/transactionok/models/warehouse.model';
import { Document } from 'src/app/valid-documents/models/document.model';
import { FormatDateService } from 'src/app/services/format-date.service';
import { SubmitFiltersService } from 'src/app/services/submit-filters.service';
@Component({
  selector: 'app-validdoc-filter',
  templateUrl: './validdoc-filter.component.html',
  styleUrls: ['./validdoc-filter.component.scss']
})
export class ValiddocFilterComponent implements OnInit {
  @Output() filterOut = new EventEmitter();
  filterData: FormGroup = new FormGroup({});
  filterWarehousesOptions = JSON.parse(localStorage.getItem('warehouses')||'{}') as Warehouse[];
  filterDocumentOptions = JSON.parse(localStorage.getItem('documents')||'{}') as any[];
  constructor(private submitFiltersService: SubmitFiltersService ) { }

  ngOnInit(): void {
    this.filterData = new FormGroup({
      warehouse: new FormControl(this.filterWarehousesOptions[0].id + 't', Validators.required),
      date: new FormControl('', Validators.required),
      transactioncode: new FormControl(''),
      documentcode: new FormControl(''),
      correlative: new FormControl(''),
    });
  }
  onSubmit() {
   /* const result: FilterData = Object.assign({}, this.filterData.value);
    console.log(result);
    result.warehouse = result.warehouse.replace('t', '');
    result.transactioncode = result.transactioncode.replace('t', '');
    result.date = this.formatDate(new Date(result.date));
    this.handleFormSubmit(result);*/
    const result = this.submitFiltersService.submit(Object.assign({}, this.filterData.value));
    this.handleFormSubmit(result);
  }

  formatDate(date:any) {
    // return this.formatDateService.formatDate(date);
  }


  handleFormSubmit($event: FilterData) {
    console.log('entre');
    console.log($event);
    this.filterOut.emit($event);
  }

}
