<app-filtersrejected (filterOut)="handleFilter($event)"></app-filtersrejected>
<div class="action-bar" style="margin-bottom: 10px;">
    <app-group-action-buttons [editDisabled]="elementSelected !== null" [showDetailDisabled]="elementSelected !== null"
    [createDisabled]="elementSelected !== null && elementSelected.documentType === 'FACTURA' && elementSelected.paymentMethod === 'A1' && (elementSelected.ref2 !== '0' || elementSelected.ref2 === null)"
    (createClickOutput)="deleteORE()" (showDetailClickOutput)="rejectTransaction()"
    (editClickOutput)="resendTransaction()"></app-group-action-buttons>
    <mat-paginator  [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
  </div>
  <div class="module-table-wrapper fade-in" *ngIf="dataSource">
    <table class="general-table" [dataSource]="dataSource"  #MatTable mat-table matSort  style="overflow-x: scroll;">
  
      <!-- Rut Columnas -->
      <ng-container matColumnDef="guid">
        <th mat-header-cell *matHeaderCellDef>GUID</th>
        <td mat-cell *matCellDef="let element"> {{element.guidDocument}} </td>
      </ng-container>
  
      <!-- Razón social Column -->
      <ng-container matColumnDef="documentDate">
        <th mat-header-cell *matHeaderCellDef>FECHA DOC.
        <th>
        <td mat-cell *matCellDef="let element"> {{element.date}} </td>
      </ng-container>
  
      <!-- Comuna Column -->
      <ng-container matColumnDef="socialReason">
        <th mat-header-cell *matHeaderCellDef>RAZON SOCIAL</th>
        <td mat-cell *matCellDef="let element"> {{element.socialReason}} </td>
      </ng-container>
  
      <!-- Estado Column -->
      <ng-container matColumnDef="numDoc">
        <th mat-header-cell *matHeaderCellDef>Nº DOCUMENTO</th>
        <td mat-cell *matCellDef="let element"> {{element.correlative}} </td>
      </ng-container>
  
      <!-- Estado Column -->
      <ng-container matColumnDef="numDocUntil">
        <th mat-header-cell *matHeaderCellDef>Nº DOCTS. HASTA</th>
        <td mat-cell *matCellDef="let element"> {{element.correlative1}} </td>
      </ng-container>
  
      <!-- Estado Columns -->
      <ng-container matColumnDef="quantDocGUID">
        <th mat-header-cell *matHeaderCellDef>CTDAD. DOCTS. GUID</th>
        <td mat-cell *matCellDef="let element"> {{element.quantityDocs}} </td>
      </ng-container>
  
      <ng-container matColumnDef="documentType">
        <th mat-header-cell *matHeaderCellDef>TIPO DOC.</th>
        <td mat-cell *matCellDef="let element"> {{element.documentType}} </td>
      </ng-container>
  
      <ng-container matColumnDef="paymentMethod">
        <th mat-header-cell *matHeaderCellDef>MÉTODO DE PAGO</th>
        <td mat-cell *matCellDef="let element"> {{element.paymentMethod}} </td>
      </ng-container>
  
      <ng-container matColumnDef="typeDocumentReference">
        <th mat-header-cell *matHeaderCellDef>TIPO DOC. REFERENCIA</th>
        <td mat-cell *matCellDef="let element"> {{element.typeDocRef}} </td>
      </ng-container>
  
      <ng-container matColumnDef="numDocReference1">
        <th mat-header-cell *matHeaderCellDef>REFERENCIA 1</th>
        <td mat-cell *matCellDef="let element"> {{element.ref1}} </td>
      </ng-container>
  
      <ng-container matColumnDef="numDocReference2">
        <th mat-header-cell *matHeaderCellDef>REFERENCIA 2</th>
        <td mat-cell *matCellDef="let element"> {{element.ref2}} </td>
      </ng-container>
  
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>MONTO BRUTO</th>
        <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
      </ng-container>
  
      <ng-container matColumnDef="estadoEnviRes">
        <th mat-header-cell *matHeaderCellDef>ESTADO ENVIO RES.</th>
        <td mat-cell *matCellDef="let element"> {{element.response}} </td>
      </ng-container>
  
      <tr class="table-header" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr  mat-row *matRowDef="let row; columns: displayedColumns;"
      (click)="clickRowHandler(row)"
      [class.row-selected]="row === this.elementSelected"></tr>
      <!--(click)="clickRowHandler(row)"-->
  
    </table>
  
  </div>