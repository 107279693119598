import { GroupActionButtonsComponent } from './../group-action-buttons/group-action-buttons.component';
import { FiltersrejectedComponent } from './filtersrejected/filtersrejected.component';
import { ValiddocFilterComponent } from './validdoc-filter/validdoc-filter.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RutSocialreasonStatusFilterComponent } from './rut-socialreason-status-filter/rut-socialreason-status-filter.component';
import { MaterialModule} from '../../core/material.module';

@NgModule({
  declarations: [
    RutSocialreasonStatusFilterComponent,
    ValiddocFilterComponent,
    FiltersrejectedComponent,
    GroupActionButtonsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports:[
    RutSocialreasonStatusFilterComponent,
    GroupActionButtonsComponent,
    ValiddocFilterComponent,
    FiltersrejectedComponent
  ]
})
export class FiltersModule { }
