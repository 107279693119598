import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-group-action-buttons',
  templateUrl: './group-action-buttons.component.html',
  styleUrls: ['./group-action-buttons.component.scss']
})
export class GroupActionButtonsComponent {
  @Output() createClickOutput = new EventEmitter();
  @Output() editClickOutput = new EventEmitter();
  @Output() deleteClickOutput = new EventEmitter();
  @Output() showDetailClickOutput = new EventEmitter();

  @Input() createDisabled = false;
  @Input() editDisabled = false;
  @Input() deleteDisabled = false;
  @Input() showDetailDisabled = false;
  @Input() createHidden = false;
  @Input() editHidden = false;
  @Input() deleteHidden = false;
  @Input() showDetailHidden = false;
  constructor() { }

  createClickHandler() {
    this.createClickOutput.emit();
  }
  editClickHandler() {
    this.editClickOutput.emit();
  }
  deleteClickHandler() {
    this.deleteClickOutput.emit();
  }
  showDetailClickHandler() {
    this.showDetailClickOutput.emit();
  }

  preventFocus(event:any) {
    event.preventDefault();
    if (event.relatedTarget) {
      event.relatedTarget.focus();
    } else {
      event.currentTarget.blur();
    }
  }

}
