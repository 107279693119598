import { ValidDocumentsByFilter } from './../valid-documents/models/validdocuments-by-filter.model';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { ValiddocumentsConstants } from '../valid-documents/valid-documents-constants';
import { ValidDocumentsByFilterInitParam } from '../valid-documents/models/validdocuments-by-filter-init-param.model';

@Injectable({
  providedIn: 'root'
})
export class ValiddocumentsService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }
  getValidDocumentsByFilter(validdocumentsParams: ValidDocumentsByFilterInitParam): Observable<ValidDocumentsByFilter> {
    let httpGetParams: HttpParams = new HttpParams();
    httpGetParams = httpGetParams.append('date', validdocumentsParams.date);
    httpGetParams = httpGetParams.append('warehouse', validdocumentsParams.warehouse);
    httpGetParams = httpGetParams.append('documentType', validdocumentsParams.documentcode);
    // tslint:disable-next-line: max-line-length
    httpGetParams = httpGetParams.append('transactioncode', validdocumentsParams.transactioncode); // transactionsrejectedParams.transactioncode);
    httpGetParams = httpGetParams.append('correlative', validdocumentsParams.correlative);
    return this.http.get<ValidDocumentsByFilter>(ValiddocumentsConstants.URL_PATH_TRANSACTIONS_OK_BY_FILTER,
      {
        params: httpGetParams, headers: new HttpHeaders().append('x-country', localStorage.getItem('country') || '{}')
      }).pipe(
        retry(3),
        catchError(this.handleError<ValidDocumentsByFilter>('getValidDocuments')));
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      this.logger.debug(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
