<div class="sidenav-cnt" [ngClass]="{'sidenav-toggler': textToggler}">
        <div class="logo-cnt" [ngClass]="{'logo-toggler': textToggler}" (click)="toggleText()">
            <div class="imgLogo" style="height: 50px; width: 70px;"></div>
            &nbsp;&nbsp;&nbsp;
            <p>VISOR CINFO SAP</p>
        </div>


    <div class="divider" [ngClass]="{'divider-toggler': textToggler}"></div>
    <mat-accordion style="margin-bottom: 12px" [ngClass]="{'avatar-toggler': textToggler}">
        <mat-expansion-panel class="user-accordion" [ngClass]="{'expansion-panel-toggled': textToggler}">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="menu-item">
                        <div class="user-avatar">
                            <img src="https://cdn.pixabay.com/photo/2017/02/25/22/04/user-icon-2098873_960_720.png" alt="user-image">
                        </div>
                        <span>Usuario Sodimac</span>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a mat-list-item [matTooltipShowDelay]="textToggler? 450 : 2000" matTooltip="Cerrar Sesión" matTooltipPosition="after" (click)="signOut()">
                    <button mat-icon-button aria-label="Cerrar sesión">
                        <mat-icon class="still">exit_to_app</mat-icon>
                    </button>
                    <span *ngIf="!textToggler">Cerrar sesion</span>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="divider" [ngClass]="{'divider-toggler': textToggler}"></div>

    <a *ngFor="let item of menuItems">
        <mat-accordion>
            <mat-expansion-panel [ngClass]="{'expansion-panel-toggled': textToggler}" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="menu-item">
                            <button mat-flat-button [matTooltipShowDelay]="textToggler? 450 : 2000" [matTooltip]="item.name"
                            matTooltipPosition="after">
                                <mat-icon>
                                    {{item.icon}}
                                </mat-icon>
                            </button>
                            <span>
                                {{ item.name }}
                            </span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list>
                    <ng-container *ngFor="let page of item.pages">
                        <a mat-list-item [matTooltipShowDelay]="textToggler? 450 : 2000" [matTooltip]="page.name"
                        matTooltipPosition="after" (click)="addNewTab(page.link, item.icon)">
                            <div class="abbreviation">
                                {{page.abbreviation}}
                            </div>
                            <span>
                                {{ page.name }}
                            </span>
                        </a>
                    </ng-container>
                </mat-nav-list>
            </mat-expansion-panel>
        </mat-accordion>
    </a>
</div>
