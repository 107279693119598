<div class="login-wrapper">
    <form [formGroup]="formGroup" (submit)="onSubmit()" class="slide-in">
      <div class="title-wrapper zoom-in">
        <h1 class="zoom-in">Visor transacciones</h1>
      </div>
      <ng-container>
        <select (change)="getCountry($event)" required>
          <option value="">Seleccione el País</option>
          <option value="CL">Chile</option>
          <option value="PE">Perú</option>
        </select>
        <button mat-flat-button class="btn-filter" type="button" (click)="azureAdLogin()">Iniciar sesión con Azure AD
          <mat-icon>login</mat-icon>
        </button>
      </ng-container>
    </form>
  </div>
  <div class="background"></div>
