import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {Tab} from './models/tab.model';
import { SkeletonComponent } from './skeleton.component';
import { AppComponent } from 'src/app/app.component';
import { ContentContainerDirective } from './content-container.directive';

@Component({
  providers:[ContentContainerDirective],
  selector: 'app-tab-content',
  template: '<ng-template appContentContainer></ng-template>'
})

export class TabContentComponent implements OnInit{
  @Input() tab:any;
  @ViewChild(ContentContainerDirective)contentContainer:ContentContainerDirective = new ContentContainerDirective(this.vc);
  constructor(private componentFactoryResolver: ComponentFactoryResolver,public vc:ViewContainerRef) {
  }

  ngOnInit() {
    const tab: Tab = this.tab;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      tab.component
    );
    const componentRef = this.contentContainer.viewContainerRef.createComponent(componentFactory);
    (componentRef.instance as SkeletonComponent).data = {parent: AppComponent};
  }
}