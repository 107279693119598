<app-validdoc-filter (filterOut)="handleFilter($event)"></app-validdoc-filter>
<mat-paginator  [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator>
<div class="module-table-wrapper fade-in" *ngIf="dataSource">
    <table class="general-table" [dataSource]="dataSource" matSort #MatTable mat-table>
  
      <!-- Rut Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>FECHA</th>
        <td mat-cell *matCellDef="let element"> {{element.date}} </td>
      </ng-container>
  
      <!-- Razón social Column -->
      <ng-container matColumnDef="warehouse">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TIENDA
        <th>
        <td mat-cell *matCellDef="let element"> {{element.warehouse}} </td>
      </ng-container>
  
      <!-- Nombre comercial Column -->
      <ng-container matColumnDef="documentType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TIPO DE DOCUMENTO</th>
        <td mat-cell *matCellDef="let element"> {{element.documentType}} </td>
      </ng-container>
  
      <!-- Región Column -->
      <ng-container matColumnDef="documentNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>NÚMERO DE DOCUMENTO</th>
        <td mat-cell *matCellDef="let element"> {{element.documentNumber}} </td>
      </ng-container>
  
      <!-- Comuna Column -->
      <ng-container matColumnDef="errorDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DESCRIPCIÓN DEL ERROR DEL DESCUENTO</th>
        <td mat-cell *matCellDef="let element"> {{element.errorDescription}} </td>
      </ng-container>
  
      <tr class="table-header" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          
          ></tr><!--(click)="clickRowHandler(row)"-->
  
    </table>
  
  </div>