import { Component, OnInit } from '@angular/core';
import { TransactionokService } from '../services/transactionok.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private transactionsokService: TransactionokService) { }

  ngOnInit(): void {
    this.getAndSaveInitialData();
  }
  private getAndSaveInitialData() {
    this.transactionsokService.getDocuments().subscribe(data => localStorage.setItem('documents', JSON.stringify(data)));
    this.transactionsokService.getTransaccionescdod().subscribe(data => localStorage.setItem('transaccionesdcto', JSON.stringify(data)));
    this.transactionsokService.getWarehouses().subscribe(data => localStorage.setItem('warehouses', JSON.stringify(data)));
  }
}
