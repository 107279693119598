import { fadeInFadeOut } from '../animations/fadeInFadeOut';
import { Component, EventEmitter, Output } from '@angular/core';
import { UserData } from './models/user-data.model';
import { User } from './models/user.model';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment.prod';
import * as CryptoJS from 'crypto-js';
import { MsalService } from '@azure/msal-angular';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from 'ngx-alerts';
import { ServicesBffService } from '../services/services-bff.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorComponent } from '../components/error/error.component';
const { config } = environment;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInFadeOut],
})
export class LoginComponent {
  @Output() userLogged = new EventEmitter();
  constructor(
    private msalService: MsalService,
    private authService: AuthService,
    private loaderService: NgxUiLoaderService,
    private alertService: AlertService,
    public servicesRol: ServicesBffService,
    public snackBar: MatSnackBar
  ) { }
  profile: any;
  isEmailShowingOrPass = true;
  formGroup: FormGroup = new FormGroup({
    username: new FormControl(null, [
      Validators.required,
      Validators.minLength(3),
    ]),
    password: new FormControl(),
  });
  hidePassword = true;
  pais: any;
  graphMeEndpoint = 'https://graph.microsoft.com/v1.0/me';
  graphGroupsEndpoint =
    'https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true';

  onSubmit() {
    const result: User = Object.assign({}, this.formGroup.value);
    this.loaderService.start();
    // tslint:disable-next-line: deprecation
    this.authService
      .doLoginUser(result)
      // tslint:disable-next-line: deprecation
      .subscribe((data) => this.loginUser(data));
  }
  private loginUser(data: UserData) {
    if (data && data.token) {
      this.userLogged.emit(data);
    } else {
      this.alertService.danger('Combinación usuario/contraseña no es valido');
    }
    this.loaderService.stop();
  }
  decryptData(data: any, encryptSecretKey: any) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  azureAdLogin() {
    if (!localStorage.getItem('country')) {
      this.snackBar.openFromComponent(ErrorComponent, {
        duration: 3000,
        data:
          'Seleccione Un Pais Valido !!!',
        verticalPosition: 'top',
        panelClass: ['warning'],
      });
    } else {
      this.msalService
        .loginPopup({
          extraScopesToConsent: ['user.read', 'openid', 'home'],
        })
        .then(() => {
          const requestObj = {
            scopes: ['user.read'],
          };
          this.msalService
            .acquireTokenSilent(requestObj)
            .then(async (tokenResponse) => {
              localStorage.setItem('token', tokenResponse.accessToken);
              const jwt: string = localStorage.getItem('token') || '';
              console.log('jwt');
              console.log(jwt);

              const payload: string = atob(jwt.split('.')[1]);
              console.log('payload');
              console.log(payload);

              //   tslint:disable-next-line: no-string-literal
              const iss: string = JSON.parse(payload)['iss'];
              console.log('iss');
              console.log(iss);

              if (iss.length > 50) {
                const key = iss;
                const descriptText = await this.decryptData(config, key);
                localStorage.setItem('uri', descriptText.uri);
              }
              console.log('tokenResponse');
              console.log(tokenResponse);

              // tslint:disable-next-line: comment-format
              // llamada al bff
              // tslint:disable-next-line: object-literal-key-quotes
              console.log('tokenResponse.accessToken');
              console.log(tokenResponse.accessToken);


              const tokenR = { token: tokenResponse.accessToken };
              // tslint:disable-next-line: deprecation
              console.log('tokenR');
              console.log(tokenR);

              this.servicesRol.getRol(tokenR).subscribe(async (response) => {
                let MyGroup = 0;
                let valor: any;
                const res = response;
                console.log('res.token');
                console.log(res.token);

                localStorage.setItem('token', res.token);
                console.log('response.token');
                console.log(response.token);

                if (response.token) {
                  console.log('response.role');
                  console.log(response.role);

                  if (response.role === 'admin') {
                    valor = false;
                    localStorage.setItem('rolVIsible', valor);
                    MyGroup = MyGroup + 1;
                  } else if (response.role === 'user') {
                    valor = true;
                    localStorage.setItem('rolVIsible', valor);
                    MyGroup = MyGroup + 1;
                  }
                }

                if (MyGroup > 0) {
                  localStorage.setItem(
                    'userData',
                    JSON.stringify({ isLoggin: true })
                  );
                  window.location.reload();
                } else {
                  // abre dialogo
                  this.snackBar.openFromComponent(ErrorComponent, {
                    duration: 3000,
                    data:
                      'Su usuario no tiene permisos para acceder a la aplicación',
                    verticalPosition: 'top',
                    panelClass: ['warning'],
                  });
                }
                this.stopLogout();
              });
            })
            .catch(() => {
              this.stopLogout();
            });
        })
        .catch(() => {
          // login failure
          this.stopLogout();
        });
    }
  }

  stopLogout() {
    setTimeout(() => {
      this.msalService.logout();
      sessionStorage.clear();
    }, 1500);
  }

  getCountry($event: any) {
    this.pais = $event.target.value;
    environment.country = '';
    environment.country = $event.target.value;
    localStorage.setItem('country', this.pais);
  }

}
