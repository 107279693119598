import { Injectable } from '@angular/core';
import { Tab } from './models/tab.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabsManagerService {
  private static counter = 0;

  public tabs: Tab[] = [];
  public tabSub = new BehaviorSubject<Tab[]>(this.tabs);
  constructor() { }

  public changeTab(tabId: number) {
    const oldTabId = this.deactivateActiveTab();
    this.tabs.filter(t => t.id === tabId)[0].active = true;
    this.tabSub.next(this.tabs);
    return oldTabId > tabId;
  }

  public removeTab(index: number) {
    this.tabs.splice(index, 1);
    if (this.tabs.length > 0) {
      this.tabs[index - 1].active = true;
    }
    this.tabSub.next(this.tabs);
  }

  public addTab(tab: Tab, icon: any) {
    this.deactivateActiveTab();
    tab.id = TabsManagerService.counter++;
    tab.active = true;
    tab.icon = icon;
    this.tabs.push(tab);
    this.tabSub.next(this.tabs);
  }

  private deactivateActiveTab() {
    let localtab;
    for (localtab of this.tabs) {
      if (localtab.active === true) {
        localtab.active = false;
        return localtab.id;
      }
    }
  }
}
