<form (ngSubmit)="onSubmit()" [formGroup]="filterData">
    <mat-card class="filter-card mat-elevation-z0">
      <div class="filter-form">
        <mat-form-field>
          <mat-label>Tienda</mat-label>
          <mat-select formControlName="warehouse">
            <mat-option *ngFor="let warehouse of filterWarehousesOptions" value="{{warehouse.id+'t'}}">{{warehouse.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Fecha de venta</mat-label>
          <input matInput [matDatepicker]="date" formControlName="date">
          <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
          <mat-datepicker #date></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Tipo documento</mat-label>
                <mat-select formControlName="transactioncode">
                    <mat-option value="">TODOS</mat-option>
                    <mat-option *ngFor="let document of filterDocumentOptions" value="{{document.transactionId}},{{document.cdoc}}">{{document.description}}</mat-option>
                </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Numero de documento</mat-label>
          <input matInput formControlName="correlative">
        </mat-form-field>
        <div [matTooltip]="filterData.invalid ? 'Completar campos obligatorios!' : ''" matTooltipPosition="above">
          <button [disabled]="filterData.invalid" mat-flat-button class="btn-filter" type="submit">Buscar</button>
        </div>
      </div>
    </mat-card>
  </form>
