import { NGXLogger } from 'ngx-logger';
import { AlertService } from 'ngx-alerts';
import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FilterData } from '../components/filters/filtersrejected/models/filterdata.model';
import { TransactionokService } from '../services/transactionok.service';
import { TransactionsOKByFilterInitParam } from './models/transactionsok-by-filter-init-param.model';
@Component({
  selector: 'app-transactionok',
  templateUrl: './transactionok.component.html',
  styleUrls: ['./transactionok.component.scss']
})
export class TransactionokComponent {

  /* ChildView */
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: true }) paginator: any;

  /* Variables */
  displayedColumns: string[] = ['guid', 'documentDate', 'warehouse', 'rut', 'socialReason', 'numDoc',
    'numDocUntil', 'quantDocGUID', 'documentType', 'typeDocumentReference',
    'numDocReference1', 'numDocReference2', 'paymentMethod', 'amount',
    'numDocSAP', 'responseSAP', 'cuo'];
  dataSource: any;
  registerCount = 0;
  transactionsokByFilter: TransactionsOKByFilterInitParam = {
    date: '',
    warehouse: '',
    transactioncode: '',
    correlative: '',
    documentcode: ''
  };
  pageInitParam = {
    page: 1,
    registersCount: 10,
    orderBy: '',
    order: ''
  };
  constructor(private transactionsokService: TransactionokService, private ngxUiLoaderService: NgxUiLoaderService,
    private logger: NGXLogger, private alertService: AlertService) {
  }
  getTransactionsOKByFilter() {
    this.ngxUiLoaderService.start();
    this.transactionsokService.getTransactionsOKByFilter(this.transactionsokByFilter)
      .subscribe(data => {
        try {
          this.dataSource = new MatTableDataSource(data as any);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.registerCount = data.transactions ? data.transactions.length : 0;
        } catch (e) {
          this.logger.debug(e);
          this.alertService.danger('Ha ocurrido un error procesando la solicitud.');
        }
        this.ngxUiLoaderService.stop();
      });
  }

  changePageData(pageEvent: PageEvent) {
    this.pageInitParam.page = pageEvent.pageIndex + 1;
    this.pageInitParam.registersCount = pageEvent.pageSize;
    this.getTransactionsOKByFilter();
  }

  handleFilter(filterData: FilterData) {
    this.transactionsokByFilter = filterData;
    this.getTransactionsOKByFilter();
  }

  sortActionHandler($event: any) {
    this.pageInitParam.orderBy = $event.active;
    this.pageInitParam.order = $event.direction;
    this.getTransactionsOKByFilter();
  }

}
