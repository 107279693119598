import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path:'tok',
    loadChildren:()=>
    import('./transactionok/transactionok.module').then(
      m => m.TransactionokModule
    )
  },
  {
    path:'trejected',
    loadChildren:()=>
    import('./transactionsrejected/transactionsrejected.module').then(
      m => m.TransactionsrejectedModule
    )
  },
  {
    path:'dvalid',
    loadChildren:()=>
    import('./valid-documents/valid-documents.module').then(
      m => m.ValidDocumentsModule
    )
  },
  {
    path:'iniciar-sesion',
    loadChildren:()=>
    import('./login/login.module').then(
      m => m.LoginModule
    )
  },
  {path: 'home', component: HomeComponent},
  {path: '**', component: HomeComponent},
  {path: '', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
