import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSort } from '@angular/material/sort';
import { NGXLogger } from 'ngx-logger';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AlertService } from 'ngx-alerts';
import { ValidDocumentsByFilterInitParam } from './models/validdocuments-by-filter-init-param.model';
import { ValiddocumentsService } from '../services/validdocuments.service';
@Component({
  selector: 'app-valid-documents',
  templateUrl: './valid-documents.component.html',
  styleUrls: ['./valid-documents.component.scss']
})
export class ValidDocumentsComponent {
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: true }) paginator: any;
  displayedColumns: string[] = ['date', 'warehouse', 'documentType', 'documentNumber', 'errorDescription'];
  dataSource: any;
  registerCount: any;
  validdocumentsByFilter: ValidDocumentsByFilterInitParam = {
    date: '',
    warehouse: '',
    transactioncode: '',
    correlative: '',
    documentcode: ''
  };
  pageInitParam = {
    page: 1,
    registersCount: 10,
    orderBy: '',
    order: ''
  };
  constructor(private validdocumentsService: ValiddocumentsService, private ngxUiLoaderService: NgxUiLoaderService,
    private logger: NGXLogger, private alertService: AlertService) { }

  getValidDocumentsByFilter() {
    this.ngxUiLoaderService.start();
    this.validdocumentsService.getValidDocumentsByFilter(this.validdocumentsByFilter)
      .subscribe(data => {
        try {
          this.dataSource = new MatTableDataSource(data as any);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.registerCount = data.transactions ? data.transactions.length : 0;
        } catch (e) {
          this.logger.debug(e);
          this.alertService.danger('Ha ocurrido un error procesando la solicitud.');
        }
        this.ngxUiLoaderService.stop();
      });
  }

  changePageData(pageEvent: PageEvent) {
    this.pageInitParam.page = pageEvent.pageIndex + 1;
    this.pageInitParam.registersCount = pageEvent.pageSize;
    this.getValidDocumentsByFilter();
  }

  handleFilter(filterData: any) {
    this.validdocumentsByFilter = filterData;
    this.getValidDocumentsByFilter();
  }

  sortActionHandler($event: any) {
    this.pageInitParam.orderBy = $event.active;
    this.pageInitParam.order = $event.direction;
    this.getValidDocumentsByFilter();
  }

}
