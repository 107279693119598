import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Warehouse } from 'src/app/transactionok/models/warehouse.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { TransactionokService } from 'src/app/services/transactionok.service';
import { SubmitFiltersService } from 'src/app/services/submit-filters.service';
@Component({
  selector: 'app-filtersrejected',
  templateUrl: './filtersrejected.component.html',
  styleUrls: ['./filtersrejected.component.scss']
})
export class FiltersrejectedComponent implements OnInit {
  @Output() filterOut = new EventEmitter();
  filterData: FormGroup = new FormGroup({});
  filterWarehousesOptions = JSON.parse(localStorage.getItem('warehouses')||'{}') as Warehouse[];
  optionSelectedDefault = 'ACTIVO';
  filterDocumentOptions = JSON.parse(localStorage.getItem('documents')||'{}') as any[];
  constructor(private submitFiltersService: SubmitFiltersService, private transactionok: TransactionokService) {
    this.transactionok.getDocuments();
   }

  ngOnInit(): void {
    this.filterData = new FormGroup({
      warehouse: new FormControl(this.filterWarehousesOptions ? this.filterWarehousesOptions[0].id + 't' : '', Validators.required),
      date: new FormControl('', Validators.required),
      transactioncode: new FormControl(''),
      documentcode: new FormControl(''),
      correlative: new FormControl(''),
      status: new FormControl('RECHAZO'),
    });
  }

  onSubmit() {
    const result = this.submitFiltersService.submit(Object.assign({}, this.filterData.value));
    this.handleFormSubmit(result);
  }

  handleFormSubmit($event:any) {
    this.filterOut.emit($event);
  }

}
