import { TransactionsOKByFilter } from './../transactionok/models/transactionsok-by-filter.model';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { TransactionsrejectedConstants } from '../transactionsrejected/transactionsrejected-constants';
import { TransactionsOKByFilterInitParam } from '../transactionok/models/transactionsok-by-filter-init-param.model';
@Injectable({
  providedIn: 'root'
})
export class TransactionsrejectedService {
  constructor(private http: HttpClient, private logger: NGXLogger) {
  }
  getTransactionsRejectedByFilter(transactionsrejectedParams: TransactionsOKByFilterInitParam): Observable<TransactionsOKByFilter> {
    let httpGetParams: HttpParams = new HttpParams();
    httpGetParams = httpGetParams.append('action', 'rejecteds');
    httpGetParams = httpGetParams.append('date', transactionsrejectedParams.date);
    httpGetParams = httpGetParams.append('warehouse', transactionsrejectedParams.warehouse);
     // tslint:disable-next-line: max-line-length
    httpGetParams = httpGetParams.append('transactioncode', transactionsrejectedParams.transactioncode); // transactionsrejectedParams.transactioncode);
    httpGetParams = httpGetParams.append('documentcode', transactionsrejectedParams.documentcode);
    httpGetParams = httpGetParams.append('correlative', transactionsrejectedParams.correlative);
    return this.http.get<TransactionsOKByFilter>(TransactionsrejectedConstants.URL_PATH_REJECTED_BY_FILTER,
      {
        params: httpGetParams, headers: new HttpHeaders().append('x-country', localStorage.getItem('country') || '{}')
      }).pipe(
        retry(3),
        catchError(this.handleError<TransactionsOKByFilter>('getTransactionsOK')));
  }
  getTransactionsNoResponseByFilter(transactionsrejectedParams: TransactionsOKByFilterInitParam): Observable<TransactionsOKByFilter> {
    let httpGetParams: HttpParams = new HttpParams();
    httpGetParams = httpGetParams.append('action', 'unanswers');
    httpGetParams = httpGetParams.append('date', transactionsrejectedParams.date);
    httpGetParams = httpGetParams.append('warehouse', transactionsrejectedParams.warehouse);
    // tslint:disable-next-line: max-line-length
    httpGetParams = httpGetParams.append('transactioncode', transactionsrejectedParams.transactioncode); // transactionsrejectedParams.transactioncode);
    httpGetParams = httpGetParams.append('documentcode', transactionsrejectedParams.documentcode);
    httpGetParams = httpGetParams.append('correlative', transactionsrejectedParams.correlative);

    return this.http.get<TransactionsOKByFilter>(TransactionsrejectedConstants.URL_PATH_NO_RESPONSE_BY_FILTER,
      {
        params: httpGetParams, headers: new HttpHeaders().append('x-country', localStorage.getItem('country') || '{}')
      }).pipe(
        retry(3),
        catchError(this.handleError<TransactionsOKByFilter>('getTransactionsOK')));
  }

  doResendTransaction(guiddocument: string, user: string): Observable<Response> {
    return this.http.get<Response>(TransactionsrejectedConstants.URL_PATH_RESEND,
      {
        headers: new HttpHeaders().append('x-country', localStorage.getItem('country') || '{}')
          // tslint:disable-next-line: max-line-length
        , params: new HttpParams().append('guid', guiddocument).append('user', user)
      }).pipe(
        retry(3),
        catchError(this.handleError<Response>('resend')));
  }

  doRejectTransaction(guiddocument: string, user: string): Observable<Response> {
    return this.http.get<Response>(TransactionsrejectedConstants.URL_PATH_REJECT,
      {
        headers: new HttpHeaders().append('x-country', localStorage.getItem('country') || '{}')
          // tslint:disable-next-line: max-line-length
          , params: new HttpParams().append('guid', guiddocument).append('user', user)
      }).pipe(
        retry(3),
        catchError(this.handleError<Response>('reject')));
  }

  doDeleteORE(guiddocument: string, user: string): Observable<Response> {
    return this.http.get<Response>(TransactionsrejectedConstants.URL_PATH_CLEAN_ORE,
      {
        headers: new HttpHeaders().append('x-country', localStorage.getItem('country') || '{}')
          , params: new HttpParams().append('guid', guiddocument).append('user', user)
      }).pipe(
        retry(3),
        catchError(this.handleError<Response>('reject')));
  }
  /************************************
   * Private Methods
   * **********************************/
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      this.logger.debug(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}