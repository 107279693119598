import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

export class HeaderInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        const header = request.clone({
            setHeaders: {
                // tslint:disable-next-line: object-literal-key-quotes
                'Authorization': 'Bearer' + ' ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'x-country': environment.country,
                'x-txref': 'asd',
                'x-cmref': 'asd',
                'x-rhsref': 'asd',
                'x-usrtx': 'qwe',
                'x-chref': 'WEB',
                'x-prref': 'asd',
                'x-commerce': 'asd',
            }
        });
        return next.handle(header);
    }//
}