export class Tab {
  public id: any;
  public title: any;
  public active: any;
  public component: any;
  public closeable: any;
  public icon: any;
  constructor(component: any, title: any, closeable = true) {
    this.component = component;
    this.title = title;
    this.closeable = closeable;
  }
}