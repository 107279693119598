import { Injectable } from '@angular/core';
import {FormatDateService} from '../services/format-date.service';

@Injectable({
  providedIn: 'root'
})
export class SubmitFiltersService {

  constructor(private formatDateService: FormatDateService) { }

  submit(result: any) {
    result.warehouse = result.warehouse.replace('t', '');
    if (result.transactioncode && result.transactioncode.length > 0) {
      const tcode = result.transactioncode;
      result.transactioncode = tcode.split(',')[0].replace('t', '');
      result.documentcode = tcode.split(',')[1];
    }
    result.date = this.formatDateService.formatDate(new Date(result.date));
    return result;
  }
}