<ngx-ui-loader></ngx-ui-loader>
<ngx-alerts></ngx-alerts>

<div class="fade-in" *ngIf="userData.isLoggin === true">
    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav mode="side"opened>
            <app-left-menu></app-left-menu>
        </mat-sidenav>
        <mat-sidenav-content>
            <div [hidden]='!sidenavStatus' class="sidenav-wrapper">
                <mat-icon>{{sidenavStatus ? 'menu_open' : 'menu'}}</mat-icon>
            </div>
            <div class="header-wrapper">
                <div class="menu-toggler-cnt">
                    <mat-icon>{{sidenavStatus ? 'menu_open' : 'menu'}}</mat-icon>
                </div>
                <app-header>
                <mat-tab-group class="main-tab-group mt-2" [selectedIndex]="selectedTab">
                    <ng-container *ngFor="let tab of tabs; let tabIndex = index"> 
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <div class="tab-wrapper" (click)="changeTab(tab.id)">
                                    <mat-icon>{{tab.icon}}</mat-icon>
                                    <span class="tab-title">{{ tab.title }}</span>
                                </div>
                                <em  class="material-icons close-tab" (click)="removeTab(tabIndex)">close</em>
                            </ng-template>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>
            </app-header>
            </div>
            <div class="sidenav-content">
                <div class="body-tab-content-wrapper">
                    <ng-container *ngFor="let tab of tabs; let tabIndex = index">
                        <div [hidden]="!tab.active" class="tab-content-wrapper" [ngClass]="{'slide-from-right': !isGreater, 'slide-from-left': isGreater}">
                            <app-tab-content [tab]="tab"></app-tab-content>
                        </div>
                    </ng-container>
                </div>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

<div class="login" *ngIf="userData.isLoggin !== true">
    <app-login></app-login>
</div>
