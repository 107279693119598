import { TransactionsRejected } from './models/transactionsrejected.model';
import { Component, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NGXLogger } from 'ngx-logger';
import { AlertService } from 'ngx-alerts';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TransactionsrejectedService } from '../services/transactionsrejected.service';
import { TransactionsOKByFilterInitParam } from './models/transactionsre-by-filter-init-param.model';
@Component({
  selector: 'app-transactionsrejected',
  templateUrl: './transactionsrejected.component.html',
  styleUrls: ['./transactionsrejected.component.scss']
})
export class TransactionsrejectedComponent {
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: true }) paginator: any;
  elementSelected: TransactionsRejected = {
    guidDocument: '',
    date: '',
    warehouse: '',
    rut: '',
    socialReason: '',
    correlative: '',
    correlative1: '',
    quantityDocs: '',
    documentType: '',
    TypeDocRef: '',
    ref1: '',
    ref2: '',
    paymentMethod: '',
    amount: '',
    sapNumber: '',
    response: '',
  };
  displayedColumns: string[] = ['guid', 'documentDate', 'numDoc',
    'numDocUntil', 'quantDocGUID', 'documentType', 'typeDocumentReference', 'paymentMethod',
    'numDocReference1', 'numDocReference2', 'amount', 'estadoEnviRes'];
  dataSource: any;
  registerCount: any;
  transactionsrejectedByFilter: TransactionsOKByFilterInitParam = {
    date: '',
    warehouse: '',
    transactioncode: '',
    correlative: '',
    documentcode: '',
    status: ''
  };
  pageInitParam = {
    page: 1,
    registersCount: 10,
    orderBy: '',
    order: ''
  };
  constructor(private transactionsrejectedService: TransactionsrejectedService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private logger: NGXLogger,
    private alertService: AlertService) { }
  getTransactionsRejectedByFilter() {
    this.ngxUiLoaderService.start();
    if (this.transactionsrejectedByFilter.status === 'RECHAZO') {
      this.transactionsrejectedService.getTransactionsRejectedByFilter(this.transactionsrejectedByFilter)
        .subscribe(data => {
          try {
            this.dataSource = new MatTableDataSource(data as any);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.registerCount = data.transactions ? data.transactions.length : 0;
          } catch (e) {
            this.logger.debug(e);
            this.alertService.danger('Ha ocurrido un error procesando la solicitud.');
          }
          this.ngxUiLoaderService.stop();
        });
    } else {
      console.log(this.transactionsrejectedByFilter);
      this.transactionsrejectedService.getTransactionsNoResponseByFilter(this.transactionsrejectedByFilter)
        .subscribe(data => {
          try {
            this.dataSource = new MatTableDataSource(data as any);
            console.log(this.dataSource)
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.registerCount = data.transactions ? data.transactions.length : 0;
          } catch (e) {
            this.logger.debug(e);
            this.alertService.danger('Ha ocurrido un error procesando la solicitud.');
          }
          this.ngxUiLoaderService.stop();
        });
    }
  }


  handleFilter(filterData: any) {
    this.transactionsrejectedByFilter = filterData;
    this.getTransactionsRejectedByFilter();
  }

  deleteORE() {
    this.ngxUiLoaderService.start();
    // tslint:disable-next-line: deprecation
    this.transactionsrejectedService.doDeleteORE(this.elementSelected.guidDocument, 'USUARIO DE PRUEBA').subscribe(_ => {
      this.alertService.info('Acción eliminar ORE ejecutada correctamente');
      this.ngxUiLoaderService.stop();
      this.getTransactionsRejectedByFilter()
      console.log('click aqui')
    });
  }

  rejectTransaction() {
    this.ngxUiLoaderService.start();
    // tslint:disable-next-line: deprecation
    this.transactionsrejectedService.doRejectTransaction(this.elementSelected.guidDocument, 'USUARIO DE PRUEBA').subscribe(_ => {
      this.alertService.info('Acción Rechazar Transacción ejecutada correctamente');
      this.ngxUiLoaderService.stop();
      this.getTransactionsRejectedByFilter()
    });
  }

  resendTransaction() {
    this.ngxUiLoaderService.start();
    // tslint:disable-next-line: deprecation
    this.transactionsrejectedService.doResendTransaction(this.elementSelected.guidDocument, 'USUARIO DE PRUEBA').subscribe(_ => {
      this.alertService.info('Acción Reenviar Transacción ejecutada correctamente');
      this.ngxUiLoaderService.stop();
      this.getTransactionsRejectedByFilter()
    });

  }

  clickRowHandler(row: TransactionsRejected) {
    this.elementSelected = row;
    console.log(this.elementSelected)
  }

}
