import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
  @Input() sidenav:any;

  sidenavIcon = 'menu';
  sidenavToggler = true;
  constructor() { }
  toggleSidenav() {
    this.sidenav.toggle();
    console.log(this.sidenav);
    this.sidenavToggler = !this.sidenavToggler;
  }
}
