import { ValiddocumentsService } from './../services/validdocuments.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidDocumentsComponent } from './valid-documents.component';
import { FiltersModule } from '../components/filters/filters.module';
import { MaterialModule } from '../core/material.module';



@NgModule({
  declarations: [
    ValidDocumentsComponent,
  ],
  imports: [
    CommonModule,
    FiltersModule,
    MaterialModule
  ],
  providers:[
    ValiddocumentsService
  ],
  entryComponents:[
    ValidDocumentsComponent
  ]
})
export class ValidDocumentsModule { }
