import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServicesBffService {

  uri: any = localStorage.getItem('uri');
  constructor(private http: HttpClient) { }

  getRol(token: any): Observable<any> {
    this.uri = localStorage.getItem('uri');
    console.log('uru');
    console.log(this.uri + 'login');

    return this.http.post<any>(this.uri + 'login', token).pipe(
      catchError(e => {
        console.log('error', e);

        return throwError(e);
      })
    );
  }

  refreshToken(): Observable<any> {
    return this.http.get<any>(this.uri + 'refresh').pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }
}