import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { User } from '../login/models/user.model';
import { UserData } from '../login/models/user-data.model';
import { AuthConstants } from '../login/auth-constants';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient, private logger: NGXLogger) {

    }

    doLoginUser(user: User): Observable<UserData> {
        return this.http.post<UserData>(AuthConstants.URL_PATH_USER_LOGIN, user)
            .pipe(retry(3),
                catchError(this.handleError<UserData>('doLoginUser')));
    }
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: HttpErrorResponse): Observable<T> => {
            this.logger.debug(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }
}