import { TransactionokService } from 'src/app/services/transactionok.service';
import { ContentContainerDirective } from './components/tabs-manager/content-container.directive';
import { TabsManagerService } from './components/tabs-manager/tabs-manager.service';
import { TabContentComponent } from './components/tabs-manager/tab-content.component';
import { TransactionsrejectedModule } from './transactionsrejected/transactionsrejected.module';
import { ValidDocumentsModule } from './valid-documents/valid-documents.module';
import { TransactionokModule } from './transactionok/transactionok.module';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { httpInterceptProviders } from './http-interceptors';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './core/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './home/home.component';
import { LoginModule } from './login/login.module';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from 'src/environments/environment.prod';
import {
  MsalAngularConfiguration, MsalInterceptor, MsalModule,
  MsalService,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR
} from '@azure/msal-angular';
import { Configuration } from 'msal';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AlertModule } from 'ngx-alerts';
import { ErrorComponent } from './components/error/error.component';
export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']]
];

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
export function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      validateAuthority: true,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin + '/visor',
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  };
}

export function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: !isIE,
    consentScopes: [
      'user.read',
      'openid',
      'profile'

    ],
    unprotectedResources: [],
    protectedResourceMap,
    extraQueryParameters: {}
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LeftMenuComponent,
    HeaderComponent,
    HomeComponent,
    TabContentComponent,
    ContentContainerDirective,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    LoginModule,
    MsalModule,
    NgxUiLoaderModule,
    AlertModule.forRoot({ maxMessages: 5, timeout: 5000 }),
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      enableSourceMaps: true,
      disableConsoleLogging: environment.production
    }),
    LoginModule,
    BrowserAnimationsModule,
    TransactionokModule,
    ValidDocumentsModule,
    TransactionsrejectedModule,
    HttpClientModule,
  ],
  providers: [
    TabsManagerService,
    TransactionokService,
    httpInterceptProviders,
    MsalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
